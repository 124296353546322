<template>
  <div>
    <nav-bar />
    <debate-hero :title="debate && debate.topic"/>
    <div class="container">

      <div class="row justify-content-center" v-if="success">
        <div class="col-md-8 my-5">
          <div class="alert alert-success">
            <p>Thank you for submitting your opinion with us.</p>
            <p>
              Our team will review and if it meets our standard, it will be
              approved
            </p>
            <router-link to="/debates">Go to debate</router-link>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!success">
        <div class="col-md-8 text-center mt-5">
          <h2>Add your Opinion (Thesis)</h2>
          <!-- <button class="px-4" style="float: right" @click="$router.go(-1)">Back</button> -->
        </div>
        <div class="col-md-8 py-5">
          <form
            action=""
            @submit.prevent="addLeadOpinion"
            enctype="multipart/form-data"
          >
            <div class="form-group">
              <label class="required" for="Description"
                >Write Your Opinion</label
              >
              <textarea
                v-model="text"
                required
                type="text"
                class="form-control"
                rows="15"
                placeholder="enter your thesis here"
              >
              </textarea>
            </div>
            <div class="form-group">
              <label for="basic-url">Upload you picture (Optional)</label>
              <div class="custom-file">
                <input
                  accept="image/*"
                  type="file"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  @change="uploadImage"
                />
                <label class="custom-file-label" for="validatedCustomFile">
                  {{ picTitle ? picTitle : "Choose file..." }}
                </label>
                <small v-if="error" class="form-text text-danger"
                  >your picture size shouldn't be more than 1MB</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="basic-url">Attach PDF (Optional)</label>
              <div class="custom-file">
                <input
                  accept="application/pdf"
                  type="file"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  @change="uploadPdf"
                />
                <label class="custom-file-label" for="validatedCustomFile">{{
                  pdfTitle ? pdfTitle : "Choose file..."
                }}</label>
              </div>
              <small v-if="error" class="form-text text-danger"
                >PDF size shouldn't be more than 1MB</small
              >
            </div>
            <div class="form-group">
              <label for="basic-url">Attach Doc (Optional)</label>
              <div class="custom-file">
                <input
                  accept=".doc,.docx,"
                  type="file"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  @change="uploadDoc"
                />
                <label class="custom-file-label" for="validatedCustomFile">
                  {{ docTitle ? docTitle : "Choose file..." }}
                </label>
                <small>{{ docTitle }}</small>
              </div>
              <small v-if="error" class="form-text text-danger"
                >Document size shouldn't be more than 1MB</small
              >
            </div>
            <label for="basic-url">Your support video (Optional)</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon3"
                  >https://www.youtube.com/</span
                >
              </div>
              <input
                v-model="video"
                type="text"
                class="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </div>
            <button class=" px-4 py-2 float-right" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <Footer />
    </div>
  </div>
</template>

<script>
import toasterMixin from "../../../mixins/toasterMinxin";
import DebateHero from './Debate-hero.vue';
import Footer from "../Footer.vue";
import NavBar from "../NavBar.vue";

export default {
  name: "thesis",
  components: {
    Footer,
    NavBar,
    DebateHero,
  },

  data() {
    return {
      debate:{},
      text: "",
      pic: "",
      pdf: "",
      doc: "",
      video: "",
      docTitle: "",
      picTitle: "",
      pdfTitle: "",
      success: "",
      error: false,
    };
  },
  methods: {
    uploadImage(event) {
      this.pic = event.target.files;
      this.picTitle = this.pic[0].name;

      if (this.pic) {
        let size;
        for (let i = 0; i < this.pic.length; i++) {
          size = Math.round(this.pic[i].size / 1024);
        }
        if (size > 2048) {
          this.error = true;
          this.toast({
            message: "picture Size is too big",
            type: "error",
          });
          return;
        }
      }
    },
    uploadDoc(event) {
      this.doc = event.target.files;
      this.docTitle = this.doc[0].name;

      if (this.doc) {
        let size;
        for (let i = 0; i < this.doc.length; i++) {
          size = Math.round(this.doc[i].size / 1024);
        }
        if (size > 2048) {
          this.error = true;
          this.toast({
            message: "document Size is too big",
            type: "error",
          });
          return;
        }
      }
    },
    uploadPdf(event) {
      this.pdf = event.target.files;
      this.pdfTitle = this.pdf[0].name;

      if (this.pdf) {
        let size;
        for (let i = 0; i < this.pdf.length; i++) {
          size = Math.round(this.pdf[i].size / 1024);
        }
        if (size > 2048) {
          this.error = true;
          this.toast({
            message: "PDF Size is too big",
            type: "error",
          });
          return;
        }
      }
    },
    addLeadOpinion() {
      const formData = new FormData();
      formData.append("debate_article_id", this.$route.params.id);
      formData.append("text", this.text);
      formData.append("video", this.video);

      if (this.pic) {
        for (let i = 0; i < this.pic.length; i++) {
          formData.append("pic", this.pic[i]);
        }
      } else {
        formData.append("pic", "");
      }

      if (this.pdf) {
        for (let i = 0; i < this.pdf.length; i++) {
          formData.append("pdf", this.pdf[i]);
        }
      } else {
        formData.append("pdf", "");
      }

      if (this.doc) {
        for (let i = 0; i < this.doc.length; i++) {
          formData.append("doc", this.doc[i]);
        }
      } else {
        formData.append("doc", "");
      }

      this.$store
        .dispatch("addThesis", formData)
        .then((respponse) => {
          this.success = true;

          this.text = null;
          this.pic = null;
          this.picTitle = null;
          this.pdf = null;
          this.pdfTitle = null;
          this.doc = null;
          this.docTitle = null;
          this.video = null;
        })
        .catch((err) => {
          if (err?.respponse?.data == undefined) {
            this.toast({
              message: "Network error.",
              type: "error",
            });
          }
        });
    },
    fetchDebateDetails() {
      this.isLoading = true;
      this.$store
        .dispatch("fetchApprovedDebateArticle")
        .then((response) => {
          const data = response.data.find(
            (debate) => debate.id == this.$route.params.id
          );
          this.debate = data;
          console.log(data)
        })
        .catch((err) => {
        });
    },
  },
  mounted() {
    this.fetchDebateDetails()
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f6f6f6");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style  scoped>
button {
  border: 2px solid #000;
  color: #000;
  padding: 5px 10px;
  text-decoration: none;
}
button:hover {
  outline: none;
  background-color: rgb(235, 41, 41);
  color: #fff;
  border: 1px solid rgb(235, 41, 41);
}

h2 {
  display: inline;
}
.required:after {
  content: " *";
  color: red;
}
@media screen and (max-width:600px) {
  h2{
    font-size: 1.8em;
  }
}
</style>